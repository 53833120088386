import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header';
import Contact from './Components/Pages/Contact';
import Gallery from './Components/Pages/Gallery';
import Footer from './Components/Footer';
import Carosel from './Components/Pages/Carosel';
import Section from './Components/Pages/Section';
import Navbar from './Components/Navbar';
import Services from './Components/Pages/Services';
import Cards from './Components/Pages/Cards';
import Sliderim from './Components/Pages/Sliderim';
import Second from './Components/Pages/Second';
import Third from './Components/Pages/Third';
import Counter from './Components/Pages/Counter';
import Projects from './Components/Pages/Projects';
import Deals from './Components/Pages/Deals';
import Proj from './Components/Pages/Proj';
import Testimo from './Components/Pages/Testimo';
import Pricetable from './Components/Pages/Pricetable';
import Chat from './Components/Pages/Chat';
import Ours from './Components/Pages/Ours';
import Galproj from './Components/Pages/Galproj';
import Technologies from './Components/Pages/Technologies';
import Clientproject from './Components/Pages/Clientproject';
import Studentproject from './Components/Pages/Studentproject';
import Hardwareproject from './Components/Pages/Hardwareproject';
import Javaproject from './Components/Projects/Javaproject';
import Pythonproject from './Components/Projects/Pythonproject';
import Machinelearn from './Components/Projects/Machinelearn';
import Miniproj from './Components/Projects/Miniproj';
import Download from './Components/Projects/Download'
import About from './Components/Pages/About';
import Contactpage from './Components/Pages/Contactpage';
import Comingsoon from './Components/Projects/Comingsoon';
import Login from './Components/AdminPanel/Login';
import Signup from './Components/AdminPanel/Signup';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route exact path="/Login" element={<Login />} />
      <Route exact path="/Ours" element={<Ours />} />
      <Route exact path="/Download" element={<Download />} />
      <Route exact path="/Javaproject" element={<Javaproject />} />
      <Route exact path="/Machinelearn" element={<Machinelearn />} />
      <Route exact path="/Contactpage" element={<Contactpage />} />
      <Route exact path="/About" element={<About />} />
      <Route exact path="/Pythonproject" element={<Pythonproject />} />
      <Route exact path="/Miniproj" element={<Miniproj />} />
      <Route exact path="/Clientproject" element={<Clientproject />} />
      <Route exact path="/Hardwareproject" element={<Hardwareproject />} />
      <Route exact path="/Studentproject" element={<Studentproject />} />
      <Route exact path="/Technologies" element={<Technologies />} />
      <Route exact path="/Galproj" element={<Galproj />} />
      <Route exact path="/Chat" element={<Chat />} />
      <Route exact path="/Pricetable" element={<Pricetable />} />
      <Route exact path="/Testimo" element={<Testimo />} />
      <Route exact path="/Header" element={<Header />} />
      <Route exact path="/Proj" element={<Proj />} />
      <Route exact path="/Second" element={<Second />} />
      <Route exact path="/Third" element={<Third />} />
      <Route exact path="/Contact" element={<Contact />} />
      <Route exact path="/Gallery" element={<Gallery />} />
      <Route exact path="/Deals" element={<Deals />} />
      <Route exact path="/Projects" element={<Projects />} />
      <Route exact path="/Footer" element={<Footer />} />
      <Route exact path="/Carosel" element={<Carosel />} />
      <Route exact path="/Section" element={<Section />} />
      <Route exact path="/Navbar" element={<Navbar />} />
      <Route exact path="/Services" element={<Services />} />
      <Route exact path="/Cards" element={<Cards />} />
      <Route exactpath="/Counter" element={<Counter />} />
      <Route exact path="/Sliderim" element={<Sliderim />} />
      <Route exact path="/Comingsoon" element={<Comingsoon />} />
      <Route exact path="/Signup" element={<Signup />} />
    </Routes>
  </Router>
);