import React, { useEffect } from 'react';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Header from '../Header';
import Footer from '../Footer';
import Forth from '../Pages/Forth';
import '../css/Testio.css';
import img1 from '../../Images/HEMADE KEDAR.jpg'
import img2 from '../../Images/anjali.jpg'
import img3 from '../../Images/vidya.jpg'
import img4 from '../../Images/Hitesh.jpg'
const Testimo = () => {
  useEffect(() => {
    $('#myCarousel').carousel();
  }, []); 
  return ( 
    <>
      <Header />
      <div className="Testimo" style={{ marginTop: '100px' }}>
        <Forth />
      </div>
      
      <div className="container-xl" style={{marginTop:'50px',marginBottom:'100px'}}>
      <div className="centered-h1" style={{marginBottom:'70px'}} >
      <h1 style={{textAlign:'center'}}>Our Teams</h1>
      </div>
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div id="myCarousel" className="carousel slide" data-ride="carousel">     
              <ol className="carousel-indicators">
                <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
                <li data-target="#myCarousel" data-slide-to="2"></li>
              </ol>
        
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="img-box"><img src="https://avatars.githubusercontent.com/u/143804652?v=4" alt="" /></div>
                  <p className="overview mt-3"><b>Devyani Chaudhari</b>,<br/> Software Developer</p>
                </div>
                <div className="carousel-item">
                  <div className="img-box"><img src={img4} alt="" /></div>
                  <p className="overview mt-3"><b>Hitesh Kothmire</b>,<br/> Software Developer</p>
                </div>
                <div className="carousel-item">
                  <div className="img-box"><img src={img1} alt="" /></div>
                  <p className="overview mt-3"><b> Kedar Hemade</b>, <br/>Web Developer</p>
                </div>
  
                <div className="carousel-item">
                  <div className="img-box"><img src={img2} alt="" /></div>
                  <p className="overview mt-3"><b>Anjali Tajane</b>,<br/> UI/UX Designer</p>
                </div>
                <div className="carousel-item">
                  <div className="img-box"><img src={img3} alt="" /></div>
                  <p className="overview mt-3"><b>Vidya Nikam</b>,<br/> Business Developer</p>
                </div>
                
           
              </div>
              {/* Carousel controls */}
              <a className="carousel-control-prev" href="#myCarousel" data-slide="prev">
                <i className="fa fa-angle-left"></i>
              </a>
              <a className="carousel-control-next" href="#myCarousel" data-slide="next">
                <i className="fa fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Testimo;
