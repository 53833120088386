import React from 'react';
import '../css/Contact.css';
const Contact = () => {
  return (
    <> 
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 mt-5">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7500.633414579746!2d73.82428613840796!3d19.953178910604638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeb5788542c27%3A0x6699b18e2e470a8!2sGlobal%20Source%20Technology%20(GST%20EMPIRE)(TEGA%20IT%20SOLUTIONS%20PVT.%20LTD.)!5e0!3m2!1sen!2sin!4v1718797219942!5m2!1sen!2sin" 
              width="100%"
              height="580"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Nashik Map"
            />
          </div>
          <div className="col-12 col-md-6">
            <div className="form" id="Contact">
              <div className="tab-content">
                <div id="signup">
                  <h1>Get In Touch</h1>
                  <form action="https://formspree.io/f/myyrrpjy" method="post">
                    <div className="field-wrap">
                      <input type="text" required name="Full Name" autoComplete="off" placeholder='Full Name'/>
                    </div>
                    <div className="field-wrap">
                      <input type="email" required name="email" autoComplete="off" placeholder='Email Address'/>
                    </div>
                    <div className="field-wrap">
                      <textarea required name="Message" autoComplete="off" placeholder='Message' />
                      <br/><br/><br/><br/>
                      <button type="submit" className="button button-block" style={{ background:'#5bced6' }}>Submit</button>
                    </div>
                  </form>
                </div>
                <div id="login"></div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
