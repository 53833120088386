
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function Sliderim () {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Corrected typo in property name
    slidesToScroll: 3 // Corrected typo in property name
  };
  return (
    <div className="w-3/4 m-auto">
      <div className="mt-50">
        <Slider {...settings} className="slider-container">
          {data.map((d, index) => ( 
           <div key={index} className="slide-container">
          <div 
            className="flex justify-center items-center"  
            style={{ 
              display: 'flex', 
              justifyContent: 'center',
              backgroundColor: '#22c1c3', // Background color from bg-indigo-500
              borderTopLeftRadius: '0.75rem', // Rounded top-left corner from rounded-t-xl
              borderTopRightRadius: '0.75rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' 
            }}>
              <div className="dark-shadow"></div>
           <img 
            src={d.img} 
            alt="" 
            className="rounded-full" 
            style={{ 
              width: '11rem', // Equivalent to w-44 (11rem = 44px)
              height: '11rem', // Equivalent to h-44 (11rem = 44px)
              borderRadius: '50%',
              marginTop:'10px',
              marginBottom:'10px' // Equivalent to rounded-full
            }} />
 
            </div>
            <div 
            className="" 
            style={{ 
              display: 'flex', // Equivalent to flex
              flexDirection: 'column', // Equivalent to flex-col
              justifyContent: 'center', // Equivalent to justify-center
              alignItems: 'center', // Equivalent to items-center
              gap: '1rem', // Equivalent to gap-4 (assuming 1rem = 4px)
              padding: '1rem',
              backgroundColor: '#e9d494',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
            }} >
              <p className="text-xl font-semibold"><strong>{d.name}</strong></p>
                <p>{d.review}</p>
                <button className="bg-indigo-500  text-lg px-6 py-1">Read More</button>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

const data = [
  {
    name: `Dev Chaudhari`,
    img : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlCijpDwF4SQJLa2CkOgBWHG4tWdN3XLwcIw&usqp=CAU`, // Updated image path with require
    review: `Our mission is to help businesses of all sizes establish a strong and effective online presence through our innovative web development solutions.`
  }, 
  {
    name: `Dev Chaudhari`,
    img : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlCijpDwF4SQJLa2CkOgBWHG4tWdN3XLwcIw&usqp=CAU`, // Updated image path with require
    review: `Our mission is to help businesses of all sizes establish a strong and effective online presence through our innovative web development solutions.`
  }, 
  {
    name: `Dev Chaudhari`,
    img : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlCijpDwF4SQJLa2CkOgBWHG4tWdN3XLwcIw&usqp=CAU`, // Updated image path with require
    review: `Our mission is to help businesses of all sizes establish a strong and effective online presence through our innovative web development solutions.`
  }, 
  {
    name: `Dev Chaudhari`,
    img : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlCijpDwF4SQJLa2CkOgBWHG4tWdN3XLwcIw&usqp=CAU`, // Updated image path with require
    review: `Our mission is to help businesses of all sizes establish a strong and effective online presence through our innovative web development solutions.`
  }, 
  {
    name: `Dev Chaudhari`,
    img : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlCijpDwF4SQJLa2CkOgBWHG4tWdN3XLwcIw&usqp=CAU`, // Updated image path with require
    review: `Our mission is to help businesses of all sizes establish a strong and effective online presence through our innovative web development solutions.`
  },
]

export default Sliderim;
