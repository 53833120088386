import React from 'react';

const Chat = () => {
  return (
    <>
      <div className="wpb_wrapper">
        <div className="mk-image mk-image-10 jupiter-donut- mk-image-lazyload align-center simple-frame inside-image" style={{marginBottom: '0px'}}>
          <div className="mk-image-container" style={{maxWidth: '927px'}}>
            <div className="mk-image-holder" style={{maxWidth: '927px'}}>
              <div className="mk-image-inner">
                <img
                  fetchpriority="high"
                  decoding="async"
                  className="lightbox-false"
                  alt="digital-marketing-web-mobile-app-development-company"
                  title="digital-marketing-web-mobile-app-development-company"
                  width="927"
                  height="720"
                  src="https://codeplateau-website.s3.ap-south-1.amazonaws.com/wp-content/uploads/2020/02/04080410/digital-marketing-web-mobile-app-development-company-1.png"
                />
                 <div className="sectionflowanimate">
        <svg width="1022px" height="453px" viewBox="0 0 1217 733" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <radialGradient id="SVGID_1_" cx="0" cy="0" r="22" gradientUnits="userSpaceOnUse">
              <stop offset="0" style={{stopColor: 'rgba(255,255,255,1)'}}/>
              <stop offset="0.1674" style={{stopColor: 'rgba(255,255,255,1)'}}/>
              <stop offset="0.1674" style={{stopColor: 'rgba(255,255,255,1)'}}/>
              <stop offset="0.3" style={{stopColor: 'rgba(255,255,255,.5)'}}/>
              <stop offset=".3433" style={{stopColor: 'rgba(239, 69, 110,.9)'}}/>
              <stop offset="1" style={{stopColor: 'rgba(239, 69, 110,0)'}}/>
            </radialGradient>
            <radialGradient id="SVGID_2_" cx="0" cy="0" r="22" gradientUnits="userSpaceOnUse">
              <stop offset="0" style={{stopColor: 'rgba(255,255,255,1)'}}/>
              <stop offset="0.1674" style={{stopColor: 'rgba(255,255,255,1)'}}/>
              <stop offset="0.1674" style={{stopColor: 'rgba(255,255,255,1)'}}/>
              <stop offset="0.3" style={{stopColor: 'rgba(255,255,255,.5)'}}/>
              <stop offset=".3433" style={{stopColor: 'rgba(0, 255, 255,.4)'}}/>
              <stop offset=".4" style={{stopColor: 'rgba(0, 255, 255,.35)'}}/>
              <stop offset="1" style={{stopColor: 'rgba(0, 255, 255,0)'}}/>
            </radialGradient>
          </defs>
          <path d="M 0 480 L 0 242 L 205 242 L 205 700 L 336 700 L 336 450 " stroke="lightgrey" strokeWidth="0" fill="none" id="theMotionPath"/>
          <circle id="cir1" cx="0" cy="0" r="20" fill="url(#SVGID_1_)"/>
          <animateMotion xlinkHref="#cir1" dur="6s" repeatCount="indefinite">
            <mpath xlinkHref="#theMotionPath"/>
          </animateMotion>
          {/* Repeat the pattern for other paths, circles, and animations */}
        </svg>
      </div>
              </div>
            </div>
            <div className="clearboth"></div>
          </div>
        </div>
      </div>

     
    </>
  )
}

export default Chat;
