// CustomCursor.js

import React, { useState, useEffect } from 'react';
import '../css/Cursor.css';

const Cursor = ({ x, y })  => {
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
      const updateCursorPosition = (e) => {
        const { clientX: x, clientY: y } = e;
        setCursorPosition({ x, y });
      };
  
      document.addEventListener('mousemove', updateCursorPosition);
  
      return () => {
        document.removeEventListener('mousemove', updateCursorPosition);
      };
    }, []);
  
    return (
      <div className="custom-cursor" style={{ left: cursorPosition.x, top: cursorPosition.y }}>
        {/* Your custom cursor content */}
      </div>
      
  );
};

export default Cursor;
