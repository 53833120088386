import React, { useEffect } from 'react';
import '../css/Third.css';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import img1 from '../../Images/best.png';
import img2 from '../../Images/believe.png';
import img3 from '../../Images/believe2.png';
import Cursor from '../Pages/Cursor';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Counter from '../Pages/Counter'
// import {
//   MDBCol,
//   MDBContainer,
//   MDBIcon,
//   MDBRow,
//   MDBTypography,
// } from "mdb-react-ui-kit";
// const CustomIcon = () => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="50"
//     height="40"
//     color="orange"
//     viewBox="0 0 24 24"
//     fill="none"
//     stroke="currentColor"
//     strokeWidth="2"
//     strokeLinecap="round"
//     strokeLinejoin="round"
//     marginLeft="20px"
//   >
//     <circle cx="12" cy="12" r="11" />
//     <path d="M18 12h-14M18 12l-4-4M18 12l-4 4" />
//   </svg>
// );
const Third = () => {
  useEffect(() => {
    AOS.init({
      duration: 3000,
      once: true,
    });
  }, []);
  return (
    <>
      <div className ="Third">
        <Cursor />
         <h1 style={{paddingTop:'70px',textAlign:'center'}}>WHY TEGA?</h1>
         <div className="container mt-5">
      <div className="row ">
        <div className="col-md-4">
          <div className="card profile-card-5">
            <div className="card-img-block">
              <img
                className="card-img-top"
                src={img3}
                alt=""
              />
            </div>
            <div className="card-body pt-0">
              <h5 className="card-title">WE BELIEVE</h5>
              <p className="card-text">
                In the power of turning your ideas into meaningful realities.
                With our infusion of innovative concepts and dedicated
                development, we strive to provide you with a truly exceptional
                and unique experience.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card profile-card-5">
            <div className="card-img-block">
              <img
                className="card-img-top"
                src={img2}
                alt=""
              />
            </div>
            <div className="card-body pt-0">
              <h5 className="card-title">WE ARE</h5>
              <p className="card-text">
                Equipped with expert teams in every domain we operate, dedicated
                to guiding you and transforming your ideas into reality.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card profile-card-5">
            <div className="card-img-block">
              <img
                className="card-img-top"
                src={img1}
                alt=""
              />
            </div>
            <div className="card-body pt-0">
              <h5 className="card-title">BEST</h5>
              <p className="card-text">
                Believe, our customers hold immense value for us, and we are
                committed to delivering the highest quality in everything we
                offer them.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
       <Counter />
    </>
  );
};
export default Third;