import React from 'react'
import Header from '../Header'
import Footer from '../Footer'

const Comingsoon = () => {
  return (
    <>
        <Header/>
    <div style={{marginTop:'150px',color:'white'}}>
      <h1 style={{textAlign:'center',fontSize:'100px'}}>Coming Soon.....!!!</h1>
    </div>
      <Footer/>
      </>
  )
}

export default Comingsoon
