import React, { useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import '../css/Gallery.css';
import { NavLink } from 'react-router-dom';
const Gallery = () => {
  const [showAppWebCards, setShowAppWebCards] = useState(false);
  const cards = [
    { number: '01', title: 'Office Environment', to: '/galproj' },
    { number: '02', title: 'Client Projects',  to: '/clientproject' },
    { number: '03', title: 'Student Projects',to: '/Studentproject' },
  ];
  const handleShowAllCards = () => {
    setShowAppWebCards(false); 
  };
  const handleShowAppWebCards = () => {
    setShowAppWebCards(true);
  };
  const filteredCards = showAppWebCards ? cards.filter(card => card.number === '01' || card.number === '04') : cards;
  return (
    <>
      <Header />
      <div className="Gallery" style={{ marginTop: '80px' }}>
        <div className="head" style={{ backgroundColor: 'gray', paddingTop: '50px', paddingBottom: '40px' }}>
          <h1 style={{ color: 'black',  textAlign:'center'}}>Gallery</h1>
          <h4 style={{ color: 'black', textAlign:'center' }}>Special packages and Offers for the well-known Clients</h4>
        </div>
          <div className="btn" style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
            <button type="button" className="btn btn-outline-light" onClick={handleShowAllCards} style={{ color: 'grey', width: '100px', borderColor: 'black' }}>All</button>
            <button type="button" className="btn btn-outline-light" onClick={handleShowAppWebCards} style={{ color: 'grey', width: '250px', marginLeft: '10px', borderColor: 'black' }}>APP/WEB DEVELOPMENT</button>
            <button type="button" className="btn btn-outline-light" style={{ color: 'grey', width: '250px', marginLeft: '10px', borderColor: 'black' }}>SEO/DIGITAL MARKETING</button>
            {/* <button type="button" className="btn btn-outline-light" style={{ color: 'grey', width: '100px', marginLeft: '10px', borderColor: 'black' }}>BULK SMS</button> */}
          </div>
        <div className="container" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' ,marginLeft:'90px'}}>
          {filteredCards.map((card, index) => (
            <div key={index} className="card">
              <div className="box">
                <div className="content">
                  <h2>{card.number}</h2>
                  <h3>{card.title}</h3>
                  <NavLink to={card.to}>Read More</NavLink>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="end" style={{ backgroundColor: 'gray', paddingTop: '50px', paddingBottom: '40px' }}>
          <h1 style={{ color: 'black', textAlign: 'center' }}>GET IN TOUCH</h1>
          <form>
            <div className="form-row" style={{ marginLeft: '450px', width: '400px' }}>
              <div className="col input-group">
                <input type="text" className="form-control" id="inlineFormInputName" placeholder="Enter Your Email Id" />
                <div className="input-group-append">
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div> */}
      </div>
      <Footer />
    </>
  );
};
export default Gallery;