import React, { useState, useEffect, useRef, useMemo } from 'react';
import '../css/Counter.css';

const Counter = () => {
    const counters = useMemo(() => [
        { target: 4, description: "Years of Experience" },
        { target: 327, description: "Projects" },
        { target: 256, description: "Clients" }
    ], []);

    const [triggeredCounts, setTriggeredCounts] = useState(Array(counters.length).fill(false));
    const countRefs = useRef([]);

    useEffect(() => {
        const handleScroll = () => {
            countRefs.current.forEach((ref, index) => {
                if (isElementInViewport(ref) && !triggeredCounts[index]) {
                    setTriggeredCounts(prev => {
                        const newTriggeredCounts = [...prev];
                        newTriggeredCounts[index] = true;
                        return newTriggeredCounts;
                    });
                    animateCount(ref, counters[index].target);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [triggeredCounts, counters]);

    const isElementInViewport = (el) => {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };
    const animateCount = (el, target) => {
        let start = 0;
        const duration = 3000;
        const stepTime = Math.abs(Math.floor(duration / target));
        const timer = setInterval(() => {
            start += 1;
            el.querySelector('.count').textContent = start;
            if (start === target) {
                clearInterval(timer);
            }
        }, stepTime);
    };

    return (
        <div className="Count">
            <div className="counter-wrapper">
                {counters.map((item, index) => (
                    <div className="counter" key={index} ref={(el) => (countRefs.current[index] = el)}>
                        <div className="count-wrapper">
                            <h1 className="count" data-target={item.target}>0</h1>
                        </div>
                        <p>{item.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Counter;
