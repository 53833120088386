import React, { useEffect } from 'react';
import img1 from '../../Images/Gallery/10.20007c9c9989ad9d28a6.jpeg';
import img2 from '../../Images/Gallery/13.06b87e98c871913d33d9.jpeg';
import img3 from '../../Images/Gallery/14.27aede8a02d33ccd3434.jpeg';
import img4 from '../../Images/Gallery/15.015827339b3ad8421f24.jpeg';
import img5 from '../../Images/Gallery/8.c4c5c9ebb9e26123dd03.jpeg';
import img6 from '../../Images/Gallery/9.96936f6a05d93dcb2de8.jpeg';
import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import Header from '../Header';
import Footer from '../Footer';

const Galproj = () => {
  useEffect(() => {
    const container = document.querySelector('#bootstrap-image-gallery');
    lightGallery(container, {
      selector: '.lg-item',
      plugins: [lgZoom, lgThumbnail],
    });
  }, []);

  return (
    <>
      <Header />
      <div className="row mx-0" id="bootstrap-image-gallery" style={{ marginTop: '120px' }}>
        <div className="col-lg-4 col-md-12 mb-4 mb-lg-0 px-2">
          <button className="lg-item" data-src={img1}>
            <div className="overlay-container">
              <img src={img1} className="w-100 shadow-1-strong mb-3" alt="Seminar" />
              <div className="overlay-text">Seminar</div>
            </div>
          </button>
          <button className="lg-item" data-src={img2}>
            <div className="overlay-container">
              <img src={img2} className="w-100 shadow-1-strong" alt="Inplant Training" />
              <div className="overlay-text">Inplant Training</div>
            </div>
          </button>
        </div>
        <div className="col-lg-4 mb-4 mb-lg-0 px-2">
          <button className="lg-item" data-src={img3}>
            <div className="overlay-container">
              <img src={img3} className="w-100 shadow-1-strong mb-3" alt="Courses" />
              <div className="overlay-text">Courses</div>
            </div>
          </button>
          <button className="lg-item" data-src={img4}>
            <div className="overlay-container">
              <img src={img4} className="w-100 shadow-1-strong mb-3" alt="Internship" />
              <div className="overlay-text">Internship</div>
            </div>
          </button>
          <button className="lg-item" data-src={img6}>
            <div className="overlay-container">
              <img src={img6} className="w-100 shadow-1-strong mb-3" alt="Industrial Visit" />
              <div className="overlay-text">Industrial Visit</div>
            </div>
          </button>
        </div>
        <div className="col-lg-4 mb-4 mb-lg-0 px-2">
          <button className="lg-item" data-src={img5}>
            <div className="overlay-container">
              <img src={img5} className="w-100 shadow-1-strong mb-3" alt="Online Meet" />
              <div className="overlay-text">Online Meet</div>
            </div>
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Galproj;
