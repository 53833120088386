import React from 'react'
import '../css/Price.css'
const Pricetable = () => {
  return (
    <>
     <section className="section pt-5 pb-5" id="pricing-plans">
      <div className="top"></div>
      <div className="container">
        {/* ***** Section Title Start ***** */}
        <div className="row">
          <div className="col-lg-12">
            <div className="center-heading">
              <h2 className="section-title">Pricing Plans</h2>
            </div>
          </div>
          <div className="offset-lg-3 col-lg-6">
            <div className="center-text">
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
          </div>
        </div>
        {/* ***** Section Title End ***** */}

        <div className="row">
          {/* ***** Pricing Item Start ***** */}
          <div className="col-lg-4 col-md-6">
            <div className="pricing-item">
              <div className="pricing-header">
                <h3 className="pricing-title">STATIC WEBSITE</h3>
              </div>
              <div className="pricing-body">
                <div className="price-wrapper">
                  <span className="currency">$</span>
                  <span className="price">10.90</span>
                  <span className="period">/Month</span>
                </div>
                <ul className="list">
                  <li className="active">Static Website</li>
                  <li className="active">Hosting free / 1 Month</li>
                  <li className="active">Free Domain / for 1 year</li>
                  <li className="active">Free Email account</li>
                  <li>Data Security and Backups</li>
                  <li>1 GB Storage</li>
                  <li>24x7 Great Support</li>
                  <li>Monthly Reports and Analytics</li>
                </ul>
              </div>
              <div className="pricing-footer">
                <a href="/" className="btn-primary-line">Select Plan</a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="pricing-item">
              <div className="pricing-header">
                <h3 className="pricing-title">DYNAMIC WEBSITES</h3>
              </div>
              <div className="pricing-body">
                <div className="price-wrapper">
                 
                  <span className="price">15999</span>
                  <span className="period">/ONWARDS</span>
                </div>
                <ul className="list">
                  <li className="active">Website with Admin Panel</li>
                  <li className="active">Hosting free / 1 month</li>
                  <li className="active">Free Domain</li>
                  <li className="active">Free Email accounts</li>
                  <li>Data Security and Backups</li>
                  <li>1 GB Storage</li>
                  <li>24x7 Great Support</li>
                  <li>Monthly Reports and Analytics</li>
                </ul>
              </div>
                <div className="pricing-footer">
                <a href="/" className="btn-primary-line">Select Plan</a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="pricing-item">
              <div className="pricing-header">
                <h3 className="pricing-title">WEB/MOBILE APP</h3>
              </div>
              <div className="pricing-body">
                <div className="price-wrapper">
             
                  <span className="price">15999</span>
                  <span className="period">/ONWARDS</span>
                </div>
                <ul className="list">
                  <li className="active">Hosting</li>
                  <li className="active">Free Domain</li>
                  <li className="active">Free updates / 1 month</li>
                  <li className="active">Free Email</li>
                  <li>Data Security and Backups</li>
                  <li>1 GB Storage</li>
                  <li>24x7 Great Support</li>
                  <li>Monthly Reports and Analytics</li>
                </ul>
              </div>
              <div className="pricing-footer">
                <a href="/" className="btn-primary-line">Select Plan</a>
              </div>
            </div>
          </div>
          
        </div>

      </div>
    </section>
    </>
  )
}

export default Pricetable
