import React from 'react'
import Header from '../Header'
import Contact from './Contact'
import Footer from '../Footer'

const Contactpage = () => {
  return (
    <>
   <Header/>
   <div style={{marginTop:'100px'}}>
   <Contact/></div>
   <Footer/>
   </>
  )
}

export default Contactpage
