import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
//import Second from '../Pages/Second'
import '../css/Project.css';
const Projects = () => {
  return ( 
    <>
      <Header />
      <div className="project" style={{ backgroundColor: 'black', marginTop: '130px', textAlign: 'center', paddingBottom:'100px' }}>
      {/* <Second /> */}
        <div style={{ fontFamily: 'Jazz LET, fantasy', alignItems: 'center' }}>
          <span className="display-1 text-white" >Digital</span>
          <span className="display-1 text-white" style={{marginLeft:'20px'}}>Solutions</span>
        </div>
        <h4 className="text-white text-center">Recent Works Gallery</h4>
        <h1 className="text-white" >Let’s Look Our Recent Project Gallery</h1>
        {/* <button  type="button" className="btn btn-outline-info float-end">View More Projects</button> */}
        <a href="/Proj" className="btn btn-outline-info float-end" style={{marginRight:'40px'}}>View More Projects</a>
     
      <div className="wrap animate pop" >
        <div className="overlay"   >
          <div className="overlay-content animate slide-left delay-2" >
            <h1 className="animate slide-left pop delay-4" style={{ fontSize:'5.25vmin',textAlign:'center', color:'white'}}>Bike Rental</h1>
            {/* <p className="animate slide-left pop delay-5" style={{color:'white',marginBottom:'2.5rem'}}>Kingdom: <em>Plantae</em></p> */}
          </div>
          <div className="image-content"></div>
          <div className="dots animate">
            <div className="dot animate slide-up delay-6"></div>
            <div className="dot animate slide-up delay-7"></div>
            <div className="dot animate slide-up delay-8"></div>
          </div>
        </div>
        <div className="text">
        <img className="inset" src="https://assets.codepen.io/4787486/oak_1.jpg" alt="" /><p style={{ lineheight: '1.4',
       color: '#0e390e',margintop: '150px'}}>Trees are woody perennial plants that are a member of the kingdom <em>Plantae</em>. All species of trees are grouped by their genus, family, and order. This helps make identifying and studying trees easier.
          Apart from providing oxygen for the planet and beauty when they bloom or turn color, trees are very useful. Certain species of hardwood and softwood trees are excellent for timber, making furniture, and paper.
          When managed properly, trees are a good source of renewable energy and construction material.Apart from providing oxygen for the planet and beauty when they bloom or turn color, 
          Apart from providing oxygen for the planet and beauty when they bloom or turn color, 
          Apart from providing oxygen for the planet and beauty when they bloom or turn color, 
          Apart from providing oxygen for the planet and beauty when they bloom or turn color, </p>
        </div>
      </div>


      </div>
      <Footer/>
      
    </>
  );
}

export default Projects;
