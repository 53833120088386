import React from 'react';
//import '../css/styles.css'; // Import CSS file for styling

const Deals = () => {
  return (
    <div className="container" style={{display:'flex',justifyContent:'space-around'}}>
      <div className="panel">
        <div className="ring">
          <div className="card card1"></div>
          <div className="border">
            <p className="title">Brazil</p>
            <div className="slide">
              <h6 className="para">Latest Deals from Heathrow</h6>
              <div className="line">
                <h6 className="para">OUT</h6> <i className="fa fa-plane" aria-hidden="true"></i>
                <h6 className="para">£849</h6>
              </div>
              <div className="line">
                <h6 className="para">RTN</h6> <i className="fa fa-plane" aria-hidden="true"></i>
                <h6 className="para">£659</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="panel">
        <div className="ring">
          <div className="card card2"></div>
          <div className="border">
            <p className="title">Belize</p>
            <div className="slide">
              <h6 className="para">Latest Deals from Heathrow</h6>
              <div className="line">
                <h6 className="para">OUT</h6> <i className="fa fa-plane" aria-hidden="true"></i>
                <h6 className="para">£999</h6>
              </div>
              <div className="line">
                <h6 className="para">RTN</h6> <i className="fa fa-plane" aria-hidden="true"></i>
                <h6 className="para">£745</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="panel">
        <div className="ring">
          <div className="card card3"></div>
          <div className="border">
            <p className="title">Egypt</p>
            <div className="slide">
              <h6 className="para">Latest Deals from Heathrow</h6>
              <div className="line">
                <h6 className="para">OUT</h6> <i className="fa fa-plane" aria-hidden="true"></i>
                <h6 className="para">£399</h6>
              </div>
              <div className="line">
                <h6 className="para">RTN</h6> <i className="fa fa-plane" aria-hidden="true"></i>
                <h6 className="para">£257</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deals;
