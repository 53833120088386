import React from 'react'
import Header from '../Header'
import Secd from './Secd'
import Footer from '../Footer'

const About = () => {
  return (
    <>
    <Header/>

    <Secd/>
    <Footer/>
    </>
  )
}

export default About
