import React from 'react';
import img from '../Images/logo.png';
import './css/Drop.css';
import {NavLink } from "react-router-dom";
const Header = () => {
  return (
    <>
      <div className="fixed-top">
        <nav className="navbar navbar-expand-lg navbar-light bg-white custom-navbar-height">
          <a href="/">
            <img src={img} alt='' style={{ height: '45px',marginLeft:'20px' }} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-center" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item mx-3">
                <NavLink className="nav-link custom-height text-dark" to="/">Home</NavLink>
              </li>
              <li className="nav-item mx-3">
                <NavLink className="nav-link custom-height text-dark" to="/About">About</NavLink>
              </li>
              <li className="nav-item mx-3">
                <NavLink className="nav-link custom-height text-dark" to="/Contactpage">Contact</NavLink>
              </li>
              <li className="nav-item mx-3">
                <NavLink className="nav-link custom-height text-dark" to="/Technologies">Technologies</NavLink>
              </li>
              <li className="nav-item mx-3">
                <NavLink className="nav-link custom-height text-dark" to="/Comingsoon">Projects</NavLink>
              </li>
              <li className="nav-item dropdown mx-3">
                <a
                  className="nav-link dropdown-toggle custom-height text-dark"
                  href="/"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Services
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <NavLink className="dropdown-item" to="/Services">Service</NavLink>
                  <NavLink className="dropdown-item" to="/Testimo">Our Team</NavLink>
                  <NavLink className="dropdown-item" to="/Gallery">Gallery</NavLink>
                  <NavLink className="dropdown-item" to="/Comingsoon">Download</NavLink>
                 
                </div>
              </li>
              
            
              {/* <li className="nav-item dropdown mx-3">
                <a
                  className="nav-link dropdown-toggle custom-height text-dark"
                  href="/"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Projects
                </a>
                
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <NavLink className="dropdown-item" to="/Comingsoon">Java Projects</NavLink>
                  <NavLink className="dropdown-item" to="/Comingsoon">Python Projects</NavLink>
                  <NavLink className="dropdown-item" to="/Comingsoon">Machine Learning</NavLink>
                  <NavLink className="dropdown-item" to="/Comingsoon">Mini Projects</NavLink>
                </div>
              </li> */}
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}
export default Header;