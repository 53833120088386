import React, { useEffect } from 'react';
import Typed from 'typed.js';
import '../css/Second.css';
import '@fortawesome/fontawesome-free/css/all.css';
import img1 from '../../Images/L1.jpg';
import img2 from '../../Images/l2.jpg';
import img3 from '../../Images/l3.jpg';
//import img4 from '../../Images/imgg2.jpg';
import img5 from '../../Images/rounded.jpg';
import { FaArrowRight } from 'react-icons/fa'; 
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
const Second = () => {
  useEffect(() => {
    const typed = new Typed(".typing_text", {
      strings: ["Design", "Develop", "Dominate"],
      typeSpeed: 120,
      backSpeed: 100,
      loop: true,
      cursorChar: '',
    });

    return () => {
      typed.destroy(); 
    };
  }, []); 

  useEffect(() => {
    AOS.init({
      duration: 3000, 
      once: true, 
    });
  }, []);


  return (
    <>   
    <div className="second">
        <div className="container">
          <div className="row">
            <div className="col-8">
              <h4>Make Your Business How!!!</h4>
              <h1>LET'S TEAR UP THE COMPETITION</h1>
              <div className="autotyping">
                "#"
                <span className="typing_text"></span>
                <span className="typed-cursor typed-cursor-blink" aria-hidden="true"></span>
                <span className="top-left"></span>
                <span className="top-right"></span>
                <span className="bot-left"></span>
                <span className="bot-right"></span>        
              </div>
              <h5>Driven by the latest technology and innovation, we are revolutionizing 
                <br/>the design and development process. The combination of creative and 
                <br/>technical skills, flexible project management, and a friendly <br/>attitude allows us to deliver innovative solutions.</h5>
              <div className="btn">         
                <button type="button" className="btn btn-outline-light">Explore Our Solution</button>
                <span >
                  <button type="button" className="btn btn-outline-light" style={{width:'160px'}}>Get Started
                  <FaArrowRight style={{ position: 'absolute', fontSize: '25px' ,marginLeft:'5px'}} /></button>
                </span>  
              </div>
              <h2> We don’t just get the job done, we devour it.</h2>
            </div>
            
            <div className="col-3" >
              <Link
                to={'/'}
                style={{
                  width: '300px',
                  height: '300px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundImage: `url(${require("../../Images/circlestar.svg").default})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat'
                }}
              >
                <img
                  src={img5}
                  alt=""
                  style={{
                    width: '100%',
                    maxWidth: '200px',
                    maxHeight: '200px',
                    animation: 'spin-slow 8s linear infinite'
                  }}
                />
                <FaArrowRight style={{ position: 'absolute', fontSize: '50px' }} />
              </Link>
            </div>
          </div>
        </div>

        <hr style={{ border: '1px solid white', marginLeft: '50px', marginRight: '50px' }} />
        
        <ul className="ele">
          <li><i className="fas fa-check-circle"></i> Dominate the Competition</li>
          <li><i className="fas fa-check-circle"></i> Run with the Pack</li>
          <li><i className="fas fa-check-circle"></i> Dominate Your Industry</li>
          <li><img src={img1} alt=''/></li>
          <li><img src={img2} alt=''/></li>
          <li><img src={img3} alt=''/></li>
        </ul>

        <div className="container">
          <div className="row">
            <div className="col-8" data-aos="fade-left">
              <h1>Experience the Power of Wolfizer:<br/> Our Story in Motion</h1>
            </div>
            <div className="col-4">
              <h4>Wolfizer is your ultimate partner in conquering the online realm. With our strategic expertise and powerful solutions, we empower brands to rise above the competition.</h4>
            </div>
          </div>
        </div>

        {/* <div className="image" style={{ marginLeft: '60px', marginTop: '30px', textAlign: 'center' }}>
          <img src={img4} alt='' style={{ width: '70%', margin: 'auto', marginBottom: '70px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} />
        </div> */}
      </div>
    </>
  );
};

export default Second;
