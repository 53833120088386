import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {ToastContainer,  toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import bcrypt from 'bcryptjs';
import Swal from 'sweetalert2';

const Signup = () => {
    const [id, idchange] = useState("");
    const [name, namechange] = useState("");
    const [password, passwordchange] = useState("");
    const [email, emailchange] = useState("");
    const navigate = useNavigate()
    const IsValidate = () => {
        let isproceed = true;
        let errormessage = 'Please enter the value in ';
    
        if (id === null || id === '') {
            isproceed = false;
         
        }
        if (name === null || name === '') {
            isproceed = false;
       
        }
        if (password === null || password === '') {
            isproceed = false;
    
        }
        return isproceed;
    };
    
    const handlesubmit = (e) => {
        e.preventDefault();
        let regobj = { id, name, password, email };
        // Hash the password using bcrypt
        const hashedPassword = bcrypt.hashSync(password, 10);
        regobj.password = hashedPassword;

        if (IsValidate()) {
            fetch("http://localhost:3032/user", {
                method: "POST",
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify(regobj)
            }).then((res) => {
                if (res.ok) {
                 
                    Swal.fire({
                        title: 'Success!',
                        text: 'Registered successfully.',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        navigate('/login');
                    });
                } else {
                    toast.error('Failed to register.');
                }
            }).catch((err) => {
                toast.error('Failed :' + err.message);
            });
        }
    };

    
  return (
    <>
        <section className="contact-wrapper p-4">
            <div className="offset-lg-3 col-sm-6 ">
                <form className="container "  onSubmit={handlesubmit}>
                    <div className="card" >
                        <div className="card-header">
                            <h1>Registration</h1>
                            
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>User Name <span className="errmsg">*</span></label>
                                        <input value={id}   onChange={e => idchange(e.target.value)} placeholder="Enter your username"className="form-control"></input>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Password <span className="errmsg">*</span></label>
                                        <input value={password}  onChange={e => passwordchange(e.target.value)} type="password" placeholder="Enter your password" className="form-control"></input>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Full Name <span className="errmsg">*</span></label>
                                        <input value={name}  onChange={e => namechange(e.target.value)} placeholder="Enter your fullname" className="form-control"></input>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Email <span className="errmsg">*</span></label>
                                        <input value={email}   onChange={e => emailchange(e.target.value)} placeholder="Enter your email" className="form-control"></input>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                   <div className="Footer " style={{ textAlign: "center"  }} >
                            <button type="submit" className="btn btn-primary w-25  ">Register</button> 
                            <Link to={'/Clogin'}   className="btn btn-danger w-25  ">Login</Link>
                            <ToastContainer/>
                            </div>
                        
                    </div>
                </form>
            </div>
</section>
    </>
  )
}

export default Signup
