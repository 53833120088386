import React from 'react';
//import Video from "../Video/video.mp4";
//import ParticlesBg from "particles-bg";
const Carosel = () => {
  return (
    <>
        {/* <ParticlesBg type="circle" bg={true} /> */}
      <div className="App"  style={{ position: 'relative' }}>
        {/* <video controls autoPlay src={Video} type="video/mp4" style={{ maxWidth: '100%', maxHeight: '100%' }} /> */}
        {/* <video controls autoPlay src={Video} type="video/mp4" style={{ width: '100%', height: '100%', objectFit: 'cover', filter: 'blur(5px)' }} /> */}
      </div>
    </>
  );
}

export default Carosel;
