import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import '../Projects/Javaproj.css'
const Table = ({ data }) => {
    return (
      <table>
        <thead>
          <tr>
            <th>Sr. No</th>
            <th>Project Category Description</th>
            <th>Download Link</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.projectCode}</td>
              {/* <td>{item.downlink}</td> */}
              <td><a href={item.downlink} target="_blank" rel="noopener noreferrer">{item.downlink}</a></td>
              </tr>
          ))}
        </tbody>
      </table>
    );
  };
  
  const projectData = [
    {
        id: 1,
        projectCode: 'Python IEEE Project Titles 2023 – 2024',
        downlink: 'https://example.com/python-ieee-2023-2024-titles.pdf',
      },
      {
        id: 2,
        projectCode: 'Java IEEE Project Titles 2023',
        downlink: 'https://example.com/java-ieee-2023-titles.pdf',
      },
      {
        id: 3,
        projectCode: 'MATLAB IEEE Final Year Projects 2023 – 2024',
        downlink: 'https://example.com/matlab-ieee-2023-2024-titles.pdf',
      },
      {
        id: 4,
        projectCode: '.Net Final Year IEEE Project Titles',
        downlink: 'https://example.com/dotnet-ieee-project-titles.pdf',
      },
      {
        id: 5,
        projectCode: 'Python Final Year Projects (Non-IEEE)',
        downlink: 'https://example.com/python-non-ieee-2023-2024-titles.pdf',
      },
      {
        id: 6,
        projectCode: 'Java Web Application Final Year Projects 2023 – 2024',
        downlink: 'https://example.com/java-web-application-2023-2024-titles.pdf',
      },
      {
        id: 7,
        projectCode: 'Dot Net Web Application Final Year Projects 2023 – 2024',
        downlink: 'https://example.com/dotnet-web-application-2023-2024-titles.pdf',
      },
      {
        id: 8,
        projectCode: 'PHP Web Application Final Year Projects 2023 – 2024',
        downlink: 'https://example.com/php-web-application-2023-2024-titles.pdf',
      },
  
  ];
const Download = () => {
  return (
    <>
    <Header/>
    <div className="javap" >
        <div className="head">
          <h1 >Free Download Final Year Project Titles (For Computer Science, CSE, IT, MCA, CS) PDF</h1>
        </div>
        </div>
        <div className="breadcrumbs-wrapper">
        <div className="container">
          <ul className="breadcrumbs" id="breadcrumbs">
            <li><a href="/">Home</a></li> <li>::</li> 
            <li>Free Download Final Year Project Titles (For Computer Science, CSE, IT, MCA, CS) PDF</li>
          </ul>
        </div>
    
      <hr className="hr-custom" />
      <p style={{marginLeft:'40px',textAlign:'justify',paddingRight:'200px'}}>Welcome to JP INFOTECH, your trusted partner for innovative and resourceful final year project titles in the field of Computer Science, Computer Engineering (CSE), Information Technology (IT), Master of Computer Applications (MCA), and Computer Science (CS). We understand the significance of your final year project, and we are here to make it a seamless journey for you.
      </p>
      <h3 style={{marginLeft:'200px',fontSize:'40px',paddingTop:'40px',paddingBottom:'30px'}}>Download Final Year Project Titles for 2023 – 2024</h3>
      
      <div className="data">
      <Table data={projectData} />
    </div>
    </div>
    <Footer/>
    </>
  )
}

export default Download
