import { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import {  toast } from "react-toastify";
import bcrypt from 'bcryptjs';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import '../AdminPanel/Login.css'
const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    sessionStorage.clear();
  }, []);
  const validate = () => {
    let result = true;
    if (username === '' || username === null) {
      result = false;
      toast.warning('Please Enter Username');
    }
    if (password === '' || password === null) {
      result = false;
      toast.warning('Please Enter Password');
    }
    return result;
  }
  
const proceedLogin = async (e) => {
  e.preventDefault();
  if (validate()) {
    try {
      const res = await fetch("http://localhost:3032/user/" + username);
      const resp = await res.json();

      if (Object.keys(resp).length === 0) {
        toast.error('Please Enter a valid username');
      } else {
        const storedHashedPassword = resp.password;
        const isPasswordMatch = bcrypt.compareSync(password, storedHashedPassword);

        if (isPasswordMatch) {
          Swal.fire({
            title: 'Login Successful',
            text: 'Welcome back!',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            navigate('/');
          });
        } else {
          toast.error('Please Enter valid credentials');
        }
      }
    } catch (err) {
      toast.error('Login Failed due to :' + err.message);
    }
  }
};
  return (
  <>
  <div className="loginBox"> <img className="user" src="https://i.ibb.co/yVGxFPR/2.png" height="100px" width="100px"/>
        <h3>Sign in here</h3>
        <form onSubmit={proceedLogin}>
            <div className="inputBox">
            <input value={username} id="uname"  type="text" name="Username" onChange={e => setUsername(e.target.value)}
                      className="form-control"  placeholder="Enter your username"></input>
            <input  value={password} id="pass" type="password" name="Password" onChange={e => setPassword(e.target.value)}
            className="form-control" placeholder="Enter your password" /> </div>
             <input type="submit" name="" value="Login"/>
        </form> 
        <a href="#">Forget Password<br/> </a>
        <div className="text-center">
            <a href='Signup' style={{color:'#59238F'}}>Sign-Up</a>
        </div>
      </div>
  </>
  )
}

export default Login
