import React from 'react'
import Header from './Components/Header'
 import Footer from './Components/Footer'
import Robot from './Components/Robot';
import Secd from './Components/Pages/Secd';
import Third from './Components/Pages/Third'
 import Ours from './Components/Pages/Ours'
 import Contact from './Components/Pages/Contact'
const App = () => {
  return (
<>
<div style={{marginTop:'120px'}}>
      <Header /> 
      </div>
      <Robot/>
    <div style={{marginTop:'130px'}}>
    <Secd/>
    </div>
    <Ours/>
    <Third/>
    <Contact/>
    <Footer /> 
       </>
  )
}

export default App
