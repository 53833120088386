import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import '../Projects/Javaproj.css'
const Table = ({ data }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Sr. No</th>
          <th>Project Code</th>
          <th>Project Title</th>
          <th>Algorithm</th>
          <th>Technology</th>
          <th>Project Cost</th>
          <th>Buy Link</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td>{item.projectCode}</td>
            <td>{item.projectTitle}</td>
            <td>{item.algorithm}</td>
            <td>{item.technology}</td>
            <td>{item.projectCost}</td>
            <td>
              <a href={item.buyLink} target="_blank" rel="noopener noreferrer">
                Buy
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const projectData = [
  {
    id: 1,
    projectCode: 'P001',
    projectTitle: 'Project Alpha',
    algorithm: 'Neural Network',
    technology: 'TensorFlow',
    projectCost: '$500',
    buyLink: 'https://example.com/buy/project-alpha'
  },
  {
    id: 2,
    projectCode: 'P002',
    projectTitle: 'Project Beta',
    algorithm: 'SHA-256',
    technology: 'Ethereum',
    projectCost: '$600',
    buyLink: 'https://example.com/buy/project-beta'
  },
  {
    id: 3,
    projectCode: 'P003',
    projectTitle: 'Project Beta',
    algorithm: 'SHA-256',
    technology: 'Ethereum',
    projectCost: '$600',
    buyLink: 'https://example.com/buy/project-beta'
  },
  {
    id: 4,
    projectCode: 'P004',
    projectTitle: 'Project Beta',
    algorithm: 'SHA-256',
    technology: 'Ethereum',
    projectCost: '$600',
    buyLink: 'https://example.com/buy/project-beta'
  },
  {
    id: 5,
    projectCode: 'P005',
    projectTitle: 'Project Beta',
    algorithm: 'SHA-256',
    technology: 'Ethereum',
    projectCost: '$600',
    buyLink: 'https://example.com/buy/project-beta'
  },
  {
    id: 6,
    projectCode: 'P006',
    projectTitle: 'Project Beta',
    algorithm: 'SHA-256',
    technology: 'Ethereum',
    projectCost: '$600',
    buyLink: 'https://example.com/buy/project-beta'
  },
  {
    id: 7,
    projectCode: 'P007',
    projectTitle: 'Project Beta',
    algorithm: 'SHA-256',
    technology: 'Ethereum',
    projectCost: '$600',
    buyLink: 'https://example.com/buy/project-beta'
  },
  {
    id: 8,
    projectCode: 'P008',
    projectTitle: 'Project Beta',
    algorithm: 'SHA-256',
    technology: 'Ethereum',
    projectCost: '$600',
    buyLink: 'https://example.com/buy/project-beta'
  },

];
const Pythonproject = () => {
  return (
    <>
<Header/>
        <div className="javap" >
        <div className="head">
          <h1 >Python Final Year Projects</h1>
        </div>
        </div>
        <div className="breadcrumbs-wrapper">
        <div className="container">
          <ul className="breadcrumbs" id="breadcrumbs">
            <li><a href="/">Home</a></li> <li>::</li> 
            <li>Python Projects</li>
          </ul>
        </div>
      </div>
    <div className="depo">
    <h3 style={{textAlign:'center',fontSize:'40px',paddingTop:'40px',paddingBottom:'30px'}}>Python Final Year Projects 2023 – 2024</h3>
    <p style={{marginLeft:'40px',textAlign:'justify',paddingRight:'40px'}}>Welcome to TegaSolution Python Final Year Projects portal, where we offer a wide array of innovative and cutting-edge Python-based projects designed to empower final year students in their academic journey. Whether you are pursuing a degree in computer science, information technology, or any related field, our Python projects are crafted to meet your academic requirements and expand your programming skills.
   From the following you can see the Latest Python Final Year Projects 2023 – 2024 on Machine Learning (ML), Deep Learning, Artificial Intelligence (AI), Data Science, NLP etc.. If you click the project title, you can see the details of the project with the output Video of it. If you wish to purchase a project, then you can purchase it through the Buy Link given. In case if the Buy Link is not available (or) if you face any problem in purchasing through Buy link, then you can email us the project title (or) whatsapp the project title, so we can send the details of the project with the payment modes of it. If you have any other specific requirements also you can mention us with the details. We develop customized projects too according to the possibilities.</p>
    <h4>Python Web Application Project Titles 2024</h4>
    <div className="data">
      <Table data={projectData} />
    </div>
    </div>
    <Footer/>
</>

  )
}

export default Pythonproject
