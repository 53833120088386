import React, { useEffect } from 'react';
import img1 from '../../Images/clientproj/ashtekar.3ff19597ad77cbd4403d.jpg'; 
import img2 from '../../Images/clientproj/clg_web.1b794e6cd54cbb008f60.jpg';
import img3 from '../../Images/clientproj/foretitan.cce33b3588df7578ae4b.jpg';
import img4 from '../../Images/clientproj/htcarb.7738a6b8eb22e21cf03c.jpg';
import img5 from '../../Images/clientproj/trust8.280556dd7a1b8a6f4b7d.jpg';
import img6 from '../../Images/clientproj/warehouse.7824a187703acd52d795.jpg';
import img7 from '../../Images/clientproj/yoga.451c6aaec72763a950e7.jpg';
import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import Header from '../Header';
import Footer from '../Footer';

const Clientproject = () => {

    useEffect(() => {
        const container = document.querySelector('#bootstrap-image-gallery');
        lightGallery(container, {
            selector: '.lg-item',
            plugins: [lgZoom, lgThumbnail],
        });
    }, []);

    return (
        <>
            <Header/>
            <div className="row mx-0" id="bootstrap-image-gallery" style={{marginTop: '120px'}}>
                <div className="col-lg-4 col-md-12 mb-4 mb-lg-0 px-2">
                    <a className="lg-item" data-src={img1}>
                        <div className="overlay-container">
                            <img src={img1} className="w-100 shadow-1-strong mb-3" alt="Ashtekar" />
                            {/* <div className="overlay-text">Project 1: Ashtekar</div> */}
                        </div>
                    </a>
                    <a className="lg-item" data-src={img2}>
                        <div className="overlay-container">
                            <img src={img2} className="w-100 shadow-1-strong" alt="CLG Web" />
                            {/* <div className="overlay-text">Project 2: CLG Web</div> */}
                        </div>
                    </a>
                </div>
                <div className="col-lg-4 mb-4 mb-lg-0 px-2">
                    <a className="lg-item" data-src={img3}>
                        <div className="overlay-container">
                            <img src={img3} className="w-100 shadow-1-strong mb-3" alt=" Foretitan" />
                            {/* <div className="overlay-text">Project 3: Foretitan</div> */}
                        </div>
                    </a>
                    <a className="lg-item" data-src={img4}>
                        <div className="overlay-container">
                            <img src={img4} className="w-100 shadow-1-strong mb-3" alt="HTC Carb" />
                            {/* <div className="overlay-text">Project 4: HTC Carb</div> */}
                        </div>
                    </a>
                    <a className="lg-item" data-src={img5}>
                        <div className="overlay-container">
                            <img src={img5} className="w-100 shadow-1-strong mb-3" alt="Trust 8" />
                            {/* <div className="overlay-text">Project 5: Trust 8</div> */}
                        </div>
                    </a>
                </div>
                <div className="col-lg-4 mb-4 mb-lg-0 px-2">
                    
                    <a className="lg-item" data-src={img6}>
                        <div className="overlay-container">
                            <img src={img6} className="w-100 shadow-1-strong mb-3" alt="Warehouse" />
                            {/* <div className="overlay-text">Project 6: Warehouse</div> */}
                        </div>
                    </a>
                    <a className="lg-item" data-src={img7}>
                        <div className="overlay-container">
                            <img src={img7} className="w-100 shadow-1-strong mb-3" alt="Yoga" />
                            {/* <div className="overlay-text">Project 7: Yoga</div> */}
                        </div>
                    </a>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default Clientproject;
