import React from 'react'
const Card = ({ title, content, imageSource }) => (
    <div style={{ 
        margin: '10px', 
        padding: '10px', 
        width: '250px', 
        borderRadius: '10px', 
        marginTop:'30px',
        marginBottom:'100px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Shadow style
        border: '1px solid rgba(0, 0, 0, 0.1)', // Highlight border
        transition: 'box-shadow 0.3s, border 0.3s', // Add transition for smoother effect
        background: 'linear-gradient(135deg, #fff 0%, #f0f0f0 100%)', // Background gradient
        '&:hover': {
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', // Change shadow on hover
          border: '1px solid rgba(0, 0, 0, 0.2)' // Change border on hover
        }
      }}>
    <img src={imageSource} alt={title} style={{ width: '100%', marginBottom: '10px' ,height:'150px',borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} />
       <div style={{ flexGrow: 1 }}> {/* This div expands to fill the remaining space */}
      <h3>{title}</h3>
      <p>{content}</p>
    </div>
    <div style={{  paddingTop: '60px', marginTop: 'auto' }}>
      <h5>READ OUT MORE...</h5>
    </div>
    </div>
  );
const Cards = () => {
  return (
    <>
    <h1 style={{textAlign:'center'}}>Industries We’re Offering</h1>
    <div  style={{marginLeft:'10px',marginRight:'10px', display: 'flex', justifyContent: 'center'}}>
    <Card
        title="website Development"
        content="Transform Your Ideas into a Captivating Digital Experience with Expert Website Development."  
        imageSource="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlCijpDwF4SQJLa2CkOgBWHG4tWdN3XLwcIw&usqp=CAU"
      />
       <Card 
        title="App Development"
        content="Transform Your Ideas into a Captivating Digital Experience with Expert Website Development."  
        imageSource="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlCijpDwF4SQJLa2CkOgBWHG4tWdN3XLwcIw&usqp=CAU"
      />
         <Card 
        title="Digital Marketing"
        content="Transform Your Ideas into a Captivating Digital Experience with Expert Website Development."  
        imageSource="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlCijpDwF4SQJLa2CkOgBWHG4tWdN3XLwcIw&usqp=CAU"
      />
      
      <Card 
        title="Website Maintenance" 
        content="Keep Your Website Running Smoothly and Secure with Hassle-Free Website Maintenance Services."  
        imageSource="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlCijpDwF4SQJLa2CkOgBWHG4tWdN3XLwcIw&usqp=CAU"
      />
     
    </div>
   {/* <h3 style={{textAlign:'center'}}>BRING THEM TOGETHER AND YOU OVERCOME THE ORDINARY. <br/>VIEW MORE SERVICE</h3> */}
    </>
  )
}

export default Cards
