import React from 'react';
import Header from '../Header'
import Footer from '../Footer'
import img1 from '../../Images/services/1.png'
import img2 from '../../Images/services/2.png'
import img3 from '../../Images/services/3.png'
import img5 from '../../Images/services/5.png'
import img6 from '../../Images/services/6.png'
import img7 from '../../Images/services/7.png'
import img8 from '../../Images/services/8.png'
import img9 from '../../Images/services/9.png'
import img10 from '../../Images/services/10.png'
import img12 from '../../Images/services/12.png'
import img13 from '../../Images/services/13.png'
import img14 from '../../Images/services/14.png'
import img15 from '../../Images/services/15.png'
import '../css/Secd.css';
const Services = () => {

  return (
    <>
      <Header />
      <div className="Services" style={{ marginTop: '109px' }}>
        <p style={{ fontSize: '50px', textAlign: 'center', marginTop: '30px', color: 'white' }}> OUR SERVICES </p>
        <hr style={{ width: '80px', margin: '0 auto', borderColor: 'white' }} />
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="row">
            <div className="col-md-4" >
              <img src={img9} alt="" className="img-fluid mx-auto d-block" width={240} />
            </div>
            <div className="col-md-8" >
              <div className="p-3" >
                <h1 className="text-white">Software DEVELOPMENT</h1>
                <p className="text-white text-justify" style={{ textAlign: 'justify' }}>
                Software development is a complex and dynamic process that involves the creation, design, implementation, testing, and maintenance of software applications and systems. It encompasses a wide range of activities and requires collaboration among various professionals including software developers, engineers, project managers, designers, and testers. The ultimate goal of software development is to produce reliable, efficient, and user-friendly software solutions that meet the specific needs of users or businesses.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row vision-container" style={{ marginTop: '80px' }}>
          <div className="col-md-8" >
            <div className="p-3">
              <h1 className="text-white" style={{ textAlign: 'left' }}>Application Development</h1>
              <p className="text-white text-justify"  style={{ textAlign: 'justify' }}>
                We offer responsive and excellent mobile app development services with all the custom functionalities and features making the app usage a delightful experience for your customers. We help your business for better operational efficiency and engagement with perfect design, development, and deployment along with extensive testing of the apps and software. Our result-driven custom software development services include Enterprise Software Development, Digital Product Development, SaaS Development, Cloud-enabled Development, Software Consulting, and Custom Software Solutions.
              </p>
            </div>
          </div>
          <div className="col-md-4" >
            <img src={img2} alt="" className="img-fluid mx-auto d-block" width={250} />
          </div>
        </div>
        <div className="row" style={{ marginTop: '80px' }}>
          <div className="col-md-4" >
            <img src={img1} alt="" className="img-fluid mx-auto d-block " width={250} />
          </div>
          <div className="col-md-8" >
            <div className="p-3">
              <h1 className="text-white">Web Development</h1>
              <p className="text-white text-justify"  style={{ textAlign: 'justify' }}>
              Web Development: We provide Static website as well as Dynamic website with the latest and updated web development technologies. Our team of web developers has expertise in custom web development with responsive web design and excellent UX. We create and maintain websites including the aspects such as Web Design, Web Programming, Web Publishing, Database Management, Domain hosting, SSL Certificate and much more. Our Web Development services also include custom web application services, delivering your web presence to help you grow your brand identity.
              </p>
            </div>
          </div>
        </div>
       
        <div className="row  vision-container" style={{ marginTop: '80px' }}>
          <div className="col-md-8" >
            <div className="p-3">
              <h1 className="text-white" style={{ textAlign: 'left' }}>IOT System</h1>
              <p className="text-white text-justify"  style={{ textAlign: 'justify' }}>
              The Internet of Things (IoT) refers to a network of physical objects—devices, vehicles, appliances, and other items—that are embedded with sensors, software, and other technologies with the aim of connecting and exchanging data with other devices and systems over the internet. An IoT system combines hardware and software components to collect, send, and act on data acquired from the environment, improving efficiency, convenience, and even safety in various domains.
              </p>
            </div>
          </div>
          <div className="col-md-4" >
            <img src={img10} alt="" className="img-fluid mx-auto d-block" width={250} />
          </div>
        </div>
        <div className="row" style={{ marginTop: '80px' }}>
          <div className="col-md-4" >
            <img src={img5} alt="" className="img-fluid mx-auto d-block " width={250} />
          </div>
          <div className="col-md-8" >
            <div className="p-3">
              <h1 className="text-white">Data Management</h1>
              <p className="text-white text-justify"  style={{ textAlign: 'justify' }}>
                Data Management plays a vital role at different levels of business processes such as monitoring customer relationships, future investments, services and product feedback, etc. We acquire, validate, store, protect, and process data created and collected by your company securely and efficiently. Our team of experts understands clearly that valuable and competent data management can maximize profits for any business. We deliver strategies that make your data more useful in structured form. We guarantee data characteristics with our services such as consistency, accuracy, completeness, auditability, timeliness, uniqueness, and orderliness. Our Data Management services include Data Capture, Mailing Lists, Data Entry, Data Profiling, Data Extraction, Data Enrichment, Image Data Entry, Medical Transcription, Annotation and Tagging, Insurance Claims Entry, Electronic Document Management, and much more.
              </p>
            </div>
          </div>
        </div>
        <div className="row  vision-container" style={{ marginTop: '80px' }}>
          <div className="col-md-8" >
            <div className="p-3">
              <h1 className="text-white" style={{ textAlign: 'left' }}>Web Research & Analysts</h1>
              <p className="text-white text-justify"  style={{ textAlign: 'justify' }}>
                We have a team of experts who are unbeatable when it comes to handling a massive volume of unstructured data. We use advanced processing and finely tuned methods to sort through a company data to provide best results that are ready for your business use, whether for analysis or storage. We utilize File Transfer Protocol (FTP) or Virtual Private Network (VPN) ensuring safety and privacy of your data. Our Data Processing services include Data Annotation, Data Cleansing, Form and Document Processing, Data Duplication, Image Processing, Check Processing, Order Processing, Localization Services, Data Processing Index, Transaction Processing, Business data Processing, Mailing list Compilation, Product Information Management, Word Processing and Formatting and much more.
              </p>
            </div>
          </div>
          <div className="col-md-4" >
            <img src={img6} alt="" className="img-fluid mx-auto d-block" width={250} />
          </div>
        </div>
        <div className="row" style={{ marginTop: '80px' }}>
          <div className="col-md-4" >
            <img src={img7} alt="" className="img-fluid mx-auto d-block" width={250} />
          </div>
          <div className="col-md-8" >
            <div className="p-3" >
              <h1 className="text-white">Digital Marketing</h1>
              <p className="text-white text-justify"  style={{ textAlign: 'justify' }}>
                We are very much focused at our work which makes us easy to make good strategies and this allow us to help our clients in solving their complex business problems. We offer scalable and high-quality marketing services from Small Business to Large Enterprises. We produce relevant and engaging content with our latest technology and modern techniques to create awareness for your brand and drive audience to your site which will then get converted into paying customers. Our Marketing services include - S.E.O, Digital Marketing, Social Media Marketing, Content Marketing, Sms Bulking, Reputation Building & Maintenance, Google Adsense and much more.
              </p>
            </div>
          </div>
        </div>
        <div className="row  vision-container" style={{ marginTop: '80px' }}>
          <div className="col-md-8" >
            <div className="p-3">
              <h1 className="text-white" style={{ textAlign: 'left' }}>E-commerce System</h1>
              <p className="text-white text-justify"  style={{ textAlign: 'justify' }}>
                Our team of experts comprises top Web Designers and Developers who specialize in Magento and WordPress solutions, consulting, site design, development, marketing, system integration, and support. This helps our clients' businesses increase their customer reach and grow their revenue through the optimal combination of overall presence, user-centric solutions, and know-your-customer tools. We provide Fraud Proof and Responsive E-commerce website development, Custom B2B/B2C E-commerce Development, Multi-Vendor Store Development, SaaS based E-commerce setup, Shopping Cart Development. E-commerce Development - (Magento 2, Woo Commerce, Shopify, Open Cart).
              </p>
            </div>
          </div>
          <div className="col-md-4" >
            <img src={img8} alt="" className="img-fluid mx-auto d-block" width={250} />
          </div>
        </div>
       

        <div className="row" style={{ marginTop: '80px' }}>
          <div className="col-md-4" >
            <img src={img3} alt="" className="img-fluid mx-auto d-block" width={250} />
          </div>
          <div className="col-md-8" >
            <div className="p-3" >
              <h1 className="text-white">Graphic Designing</h1>
              <p className="text-white text-justify"  style={{ textAlign: 'justify' }}>
                Collaboration is the main key to our work. We take time to know about you and your business to create best design outcomes that are well-suited to your business and plan various responsive strategies for the growth of your business. We do smart & creative work to convey your business to the audience through impressive graphic designs transforming the standard of your online business to higher level across various platforms. Our Graphic Design services include Logo/3D Designs, Business cards, Letter Heads, Illustration, Brochure design, Poster Design, Flyer Design, Social Media Design, Banner Ads, Catalog Design, Invitation Design and much more.
              </p>
            </div>
          </div>
        </div>
        <div className="row  vision-container" style={{ marginTop: '80px' }}>
          <div className="col-md-8" >
            <div className="p-3">
              <h1 className="text-white" style={{ textAlign: 'left' }}>project consultancy</h1>
              <p className="text-white text-justify"  style={{ textAlign: 'justify' }}>
              Project consultancy involves providing expert advice, guidance, and support to organizations in planning, executing, and managing projects. Consultants bring specialized knowledge and experience to help organizations achieve their project goals efficiently and effectively. Project consultancy services can be applied across various industries and types of projects, including IT, construction, engineering, healthcare, and more.
              </p>
            </div>
          </div>
          <div className="col-md-4" >
            <img src={img12} alt="" className="img-fluid mx-auto d-block" width={250} />
          </div>
        </div>
        <div className="row" style={{ marginTop: '80px' }}>
          <div className="col-md-4" >
            <img src={img13} alt="" className="img-fluid mx-auto d-block " width={250} />
          </div>
          <div className="col-md-8" >
            <div className="p-3">
              <h1 className="text-white">Internship</h1>
              <p className="text-white text-justify"  style={{ textAlign: 'justify' }}>
              An internship is a structured and temporary work experience provided by an organization to students or recent graduates, typically lasting from a few weeks to several months. The primary purpose of an internship is to offer practical exposure to the workplace environment, allowing interns to gain hands-on experience in their field of study or interest. 
              </p>
            </div>
          </div>
        </div>
        <div className="row  vision-container" style={{ marginTop: '80px' }}>
          <div className="col-md-8" >
            <div className="p-3">
              <h1 className="text-white" style={{ textAlign: 'left' }}>courses</h1>
              <p className="text-white text-justify"  style={{ textAlign: 'justify' }}>
              Software courses are educational programs designed to teach individuals the skills and knowledge required to develop, manage, and maintain software applications. These courses cover a wide range of topics, from programming languages and software development methodologies to specific tools and technologies used in the industry.
              </p>
            </div>
          </div>
          <div className="col-md-4" >
            <img src={img14} alt="" className="img-fluid mx-auto d-block" width={250} />
          </div>
        </div>
        <div className="row" style={{ marginTop: '80px' }}>
          <div className="col-md-4" >
            <img src={img15} alt="" className="img-fluid mx-auto d-block " width={250} />
          </div>
          <div className="col-md-8" >
            <div className="p-3">
              <h1 className="text-white">Embedded Systems</h1>
              <p className="text-white text-justify"  style={{ textAlign: 'justify' }}>
                Data Management plays a vital role at different levels of business processes such as monitoring customer relationships, future investments, services and product feedback, etc. We acquire, validate, store, protect, and process data created and collected by your company securely and efficiently. Our team of experts understands clearly that valuable and competent data management can maximize profits for any business. We deliver strategies that make your data more useful in structured form. We guarantee data characteristics with our services such as consistency, accuracy, completeness, auditability, timeliness, uniqueness, and orderliness. Our Data Management services include Data Capture, Mailing Lists, Data Entry, Data Profiling, Data Extraction, Data Enrichment, Image Data Entry, Medical Transcription, Annotation and Tagging, Insurance Claims Entry, Electronic Document Management, and much more.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </>
  )
}
export default Services