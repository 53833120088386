import React from 'react'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import img from '../Images/logo2.png';
import {NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Footer.css'
const Footer = () => {
  return (
    <>
      <MDBFooter bgColor='#040A20' className='text-center text-lg-start text-muted '>
        <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
          <div className='me-5 d-none d-lg-block'>
            <span></span>
          </div>
        </section>
        <section className=''>
          <MDBContainer className='text-center text-md-start mt-5'>
            <MDBRow className='mt-3'>
              <MDBCol xs="12" md="4" lg="4" xl="4" className='img mx-auto mb-4 text-center'>
                <img src={img} alt='' style={{ height: '70px' }} />
              </MDBCol>
              <MDBCol xs="12" md="4" lg="4" xl="4" className='ab mx-auto mb-4 text-center'>
                {/* <h6 className='text-uppercase fw-bold mb-2'>
                  <MDBIcon icon="gem" className="me-1" /> About
                </h6> */}
                <p style={{ color: 'white' }}> <NavLink to='/' className='text-reset'>Home</NavLink> </p>
                <p style={{ color: 'white' }}> <NavLink to='/About' className='text-reset'>About</NavLink> </p>
                <p style={{ color: 'white' }}> <NavLink to='/Contactpage' className='text-reset'>Contact</NavLink> </p>
                <p style={{ color: 'white' }}> <NavLink to='/Services' className='text-reset'>Services</NavLink> </p>
              </MDBCol>
              <MDBCol xs="12" md="4" lg="4" xl="4" className='con mx-auto mb-4 text-center'>
                {/* <h6 className='text-uppercase fw-bold mb-4 text-white'>Contact</h6> */}
                <div className='d-flex align-items-center text-white mb-2'>
                  <MDBIcon icon="home" className="me-2 mx-1" />
                  <span> Datta Mandir Stop, Nashik Road, Nashik</span>
                </div>
                <div className='d-flex align-items-center text-white mb-2'>
                  <MDBIcon icon="envelope" className="me-2 mx-1" />
                  <span> tegaitsolutions@gmail.com</span>
                </div>
                <div className='d-flex align-items-center text-white mb-2'>
                  <MDBIcon icon="phone" className="me-2 mx-1" />
                  <span> +91 9309707664 / +91 7218672333</span>
                </div>
                {/* <div className='d-flex align-items-center text-white'>
                  <MDBIcon icon="phone" className="me-2 mx-1" />
                  <span> +91 7218672333</span>
                </div> */}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
        <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          © 2024 Copyright:&nbsp;
          <a className='text-reset fw-bold' href='https://tegaitsolutions.com/'>
            tegaitsolutions.com
          </a>
        </div>
      </MDBFooter>
    </>
  )
}
export default Footer