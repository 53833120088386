import React from "react";
import "../css/Ours.css";
import img1 from "../../Images/8.png";
import img2 from "../../Images/9.png";
import img3 from "../../Images/10.png";
import img4 from "../../Images/11.png";
const Ours = () => {
  return (
    <>
      <section>
        <div className="container py-5 ">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-12 ">
              <figure
                className="bg-body-tertiary p-4"
                style={{
                  borderLeft: ".35rem solid #6BD9C8",
                  borderTop: "1px solid #eee",
                  borderRight: "1px solid #eee",
                  borderBottom: "1px solid #eee",
                }}
              > 
                <i
                  className="fas fa-quote-left fa-2x mb-4"
                  style={{ color: "#6BD9C8" }}
                ></i>
                <blockquote className="blockquote pb-2">
                  <p style={{ color: "white" }}>
                  In this modern Era TEGA IT solutions PVT LTD begins by thoroughly understanding your requirements. Our team then designs a system tailored to meet your current needs while considering the future growth of your organization. Leveraging our extensive business domain knowledge and technological expertise, we implement solutions using proven methodologies. This approach ensures the delivery of high-quality  product and services.
                  </p>
                </blockquote>
              </figure>
            </div>
          </div>
        </div>
      </section>
      <h1 style={{textAlign:"center"}}>Our Services</h1>
      <br />
      <br />
      <div class="section_our_solution">
  <div class="row">
    <div class="col-lg-12">
      <div class="our_solution_category">
        <div class="solution_cards_box d-flex justify-content-center justify-content-lg-start" >
          <div class="solution_card">
            <div class="hover_color_bubble"></div>
            <div class="so_top_icon">
              <img src={img1} alt="" />
            </div>
            <div class="solu_title">
              <h3>Cross-platform mobile applications</h3>
            </div>
            <div class="solu_description text-justify">
              <p>
                Our team of application developers, specialising in
                cross-platform mobile development, works to optimise the
                stability and functionality of your business by crafting
                applications that harmonise perfectly with your business model.
              </p>
            </div>
          </div>
          <div class="solution_card">
            <div class="hover_color_bubble"></div>
            <div class="so_top_icon">
              <img src={img2} alt="" />
            </div>
            <div class="solu_title">
              <h3>Web applications</h3>
            </div>
            <div class="solu_description text-justify">
              <p>
                Our core objective revolves around delivering top-notch
                services to our clients. We achieve this by bringing their
                ideas to life through the creative presentation and introduction
                of visual elements and graphics on the digital platform.
              </p>
            </div>
          </div>
          <div class="solution_card">
            <div class="hover_color_bubble"></div>
            <div class="so_top_icon">
              <img src={img3} alt="" />
            </div>
            <div class="solu_title">
              <h3>Digital Marketing</h3>
            </div>
            <div class="solu_description text-justify">
              <p>
                Boosting your brand's visibility can be achieved effectively
                through digital marketing. Our digital marketing solutions
                encompass a broad spectrum of services, ranging from establishing
                an initial online business presence to generating a steady stream
                of customers for your brand.
              </p>
            </div>
          </div>
          <div class="solution_card">
            <div class="hover_color_bubble"></div>
            <div class="so_top_icon">
              <img src={img4} alt="" />
            </div>
            <div class="solu_title">
              <h3>Project Consulting</h3>
            </div>
            <div class="solu_description text-justify">
              <p>
                Our service offering includes project consulting, where our
                experienced professionals provide expert guidance to ensure
                project success. We assess risks, optimize resources, and align
                goals to enhance efficiency and control costs, benefiting
                organizations across various industries.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </>
  );
};
export default Ours;