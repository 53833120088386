import React from 'react'
const myImage1 = require('../../Images/dt.jpg');
const myImage2 = require('../../Images/dt2.jpg');
const myImage3 = require('../../Images/dt3.jpg');
const Section = () => {
  return (
    <>
    <br/>
    <section>
    <h1 style={{textAlign:'center'}}>Services</h1>
    </section>
    <br/>
    <div class="row row-cols-1 row-cols-md-3 g-4 text-center" style={{marginTop:'10px'}}>
      
  <div class="col ">
    <div class="card" style={{ maxWidth: '400px',marginLeft: '50px' ,marginBottom:'100px'}}>
      <img src={myImage1} className="card-img-top" alt="Hollywood Sign on The Hill" style={{width: '100px',marginTop:'20px', margin: 'auto'}}/>
      <div class="card-body">
        <h4 class="card-title">Digital Transformation</h4>
        <p class="card-text">
        Revolutionizing businesses through tech integration for agility, innovation....
        </p>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="card" style={{ maxWidth: '400px' }}>
    <img src={myImage2} className="card-img-top" alt="Hollywood Sign on The Hill" style={{width: '100px', margin: 'auto' ,marginTop:'20px'}}/>
      <div class="card-body">
        <h4 class="card-title">IT consulting</h4>
        <p class="card-text">
        Strategic IT solutions for optimal business performance and growth...
        </p>
      </div>
    </div>
  </div>

  <div class="col">
    <div class="card" style={{ maxWidth: '400px' }}>
    <img src={myImage3} className="card-img-top" alt="Hollywood Sign on The Hill" style={{width: '100px', margin: 'auto',marginTop:'20px'}}/>
      <div class="card-body">
        <h4 class="card-title">Custom application development</h4>
        <p class="card-text">Custom application development involves creating software solutions specifically...</p>
      </div>
    </div>
  </div>
</div>
   
    </>
  )
}

export default Section
