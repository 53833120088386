import React, { useEffect } from 'react';
import img1 from '../../Images/studproj/age_gender.45edfa8aabcf97873739.jpg';
import img2 from '../../Images/studproj/criminal.4ed05fb87a4ae8be02b6.jpg';
import img3 from '../../Images/studproj/crop.81b15f4c1851bf4538b9.jpg';
import img4 from '../../Images/studproj/diabetes.0461b524e60bd3647cc7.jpg';
import img5 from '../../Images/studproj/lungs.0e9be777f581ab98d363.jpg';
import img6 from '../../Images/studproj/under_water.23dbcaaf8a35bda7c935.jpg';
import img7 from '../../Images/studproj/multi.5c6e245976855b83c4c0.jpg';
import img8 from '../../Images/studproj/pdf_summary.b5c86ad0c8e9c25ab489.jpg';
import img9 from '../../Images/hardwareproj/1.1bc2241af56c46ab97b9.jpeg'; 
import img10 from '../../Images/hardwareproj/12.44a8dd7a137e542f0f2b.jpeg';
import img11 from '../../Images/hardwareproj/2.9dee4ed1c720bc6ced11.jpeg';
import img13 from '../../Images/hardwareproj/5.fc2fe2e39334a3ca1335.jpeg';
import img14 from '../../Images/hardwareproj/6.d10cedc985bc6daaa607.jpeg';
import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import Header from '../Header';
import Footer from '../Footer';


const Studentproject = () => { 
    useEffect(() => {
        const container = document.querySelector('#bootstrap-image-gallery');
        lightGallery(container, {
            selector: '.lg-item',
            plugins: [lgZoom, lgThumbnail],
        });
    }, []);

    return (
        <>
            <Header/>
            <div className="row mx-0" id="bootstrap-image-gallery" style={{marginTop:'120px'}}>
                <div className="col-lg-4 col-md-12 mb-4 mb-lg-0 px-2">
                    <button className="lg-item" data-src={img1}>
                        <div className="overlay-container">
                            <img src={img1} className="w-100 shadow-1-strong mb-3" alt="Age and Gender Prediction" />
                            <div className="overlay-text"> Age and Gender Prediction</div>
                        </div>
                    </button>
                    <button className="lg-item" data-src={img2}>
                        <div className="overlay-container">
                            <img src={img2} className="w-100 shadow-1-strong" alt="Criminal Prediction" />
                            <div className="overlay-text"> Criminal Prediction</div>
                        </div>
                    </button>
                    <button className="lg-item" data-src={img14}>
                        <div className="overlay-container">
                            <img src={img14} className="w-100 shadow-1-strong mb-3" alt="IOT Based Rain Alert System " />
                            <div className="overlay-text"> IOT Based Rain Alert System </div>
                        </div>
                    </button>
                    <button className="lg-item" data-src={img3}>
                        <div className="overlay-container">
                            <img src={img3} className="w-100 shadow-1-strong mb-3" alt="Crop Prediction" />
                            <div className="overlay-text">Crop Prediction</div>
                        </div>
                    </button>
                    <button className="lg-item" data-src={img4}>
                        <div className="overlay-container">
                            <img src={img4} className="w-100 shadow-1-strong mb-3" alt="Diabetes Prediction" />
                            <div className="overlay-text"> Diabetes Prediction</div>
                        </div>
                    </button>

                </div>

                <div className="col-lg-4 mb-4 mb-lg-0 px-2">
                    <button className="lg-item" data-src={img5}>
                        <div className="overlay-container">
                            <img src={img5} className="w-100 shadow-1-strong mb-3" alt="Lung Disease Prediction" />
                            <div className="overlay-text"> Lung Disease Prediction</div>
                        </div>
                    </button>
                    <button className="lg-item" data-src={img13}>
                        <div className="overlay-container">
                            <img src={img13} className="w-100 shadow-1-strong mb-3" alt="IOT Based Home Automation" />
                            <div className="overlay-text"> IOT Based Home Automation</div>
                        </div>
                    </button>
                    <button className="lg-item" data-src={img6}>
                        <div className="overlay-container">
                            <img src={img6} className="w-100 shadow-1-strong mb-3" alt=" Underwater Exploration" />
                            <div className="overlay-text"> Underwater Exploration</div>
                        </div>
                    </button>
                    <button className="lg-item" data-src={img7}>
                        <div className="overlay-container">
                            <img src={img7} className="w-100 shadow-1-strong mb-3" alt="Multi-modal Analysis" />
                            <div className="overlay-text">Multi-modal Analysis</div>
                        </div>
                    </button>
                    <button className="lg-item" data-src={img8}>
                        <div className="overlay-container">
                            <img src={img8} className="w-100 shadow-1-strong mb-3" alt="PDF Summary" />
                            <div className="overlay-text"> PDF Summary</div>
                        </div>
                    </button>

                </div>
                <div className="col-lg-4 mb-4 mb-lg-0 px-2">
                    <button className="lg-item" data-src={img9}>
                        <div className="overlay-container">
                            <img src={img9} className="w-100 shadow-1-strong mb-3" alt="Smart Hospital Saline System" />
                            <div className="overlay-text">Smart Hospital Saline System</div>
                        </div>
                    </button>
                    <button className="lg-item" data-src={img10}>
                        <div className="overlay-container">
                            <img src={img10} className="w-100 shadow-1-strong mb-3" alt="Delivery Drone" />
                            <div className="overlay-text">Delivery Drone</div>
                        </div>
                    </button>
                    <button className="lg-item" data-src={img11}>
                        <div className="overlay-container">
                            <img src={img11} className="w-100 shadow-1-strong mb-3" alt="IOT Based Smart Wheel Chair" />
                            <div className="overlay-text">IOT Based Smart Wheel Chair</div>
                        </div>
                    </button>
                    
                    
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default Studentproject;
