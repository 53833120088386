import React, { useEffect } from 'react';
import '../css/hard.css';
 import img1 from '../../Images/hardwareproj/1.1bc2241af56c46ab97b9.jpeg'; 
import img2 from '../../Images/hardwareproj/12.44a8dd7a137e542f0f2b.jpeg';
import img3 from '../../Images/hardwareproj/2.9dee4ed1c720bc6ced11.jpeg';
import img4 from '../../Images/hardwareproj/4.4a189e9dae14a5037787.jpeg';
import img5 from '../../Images/hardwareproj/5.fc2fe2e39334a3ca1335.jpeg';
import img6 from '../../Images/hardwareproj/6.d10cedc985bc6daaa607.jpeg';

import Header from '../Header';
// import Footer from '../Footer';

const Hardwareproject = () => {
  
  useEffect(() => {
    const next = document.querySelector('.next');
    const prev = document.querySelector('.prev');
    
    const handleNextClick = () => {
      let items = document.querySelectorAll('.item');
      document.querySelector('.slide').appendChild(items[0]);
    };

    const handlePrevClick = () => {
      let items = document.querySelectorAll('.item');
      document.querySelector('.slide').prepend(items[items.length - 1]);
    };
    
    next.addEventListener('click', handleNextClick);
    prev.addEventListener('click', handlePrevClick);

    // Cleanup event listeners on component unmount
    return () => {
      next.removeEventListener('click', handleNextClick);
      prev.removeEventListener('click', handlePrevClick);
    };
  }, []);

  return (
    <>
    <Header/>

      <div className="container" style={{position:'absolute',top:'120px',
        left:'30px',transform:'translate(-50, -50)',width:'1000px',height:'450px',background:'#F5F5F5',boxShadow:'0 30px 50px #DBDBDB'
      }}>

          <div className="slide">
              <div className="item" style={{backgroundImage:`url(${img1})`}}>
                  <div className="content">
                      <div className="name">Switzerland</div>
                      <div className="des">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!</div>
                      {/* <button>See More</button> */}
                  </div>
              </div>
              <div className="item" style={{backgroundImage:`url(${img2})`}}>
                  <div className="content">
                      <div className="name">Finland</div>
                      <div className="des">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!</div>
                      {/* <button>See More</button> */}
                  </div>
              </div>
              <div className="item" style={{backgroundImage:`url(${img3})`}}>
                  <div className="content">
                      <div className="name">Iceland</div>
                      <div className="des">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!</div>
                      {/* <button>See More</button> */}
                  </div>
              </div>
              <div className="item" style={{backgroundImage:`url(${img4})`}}>
                  <div className="content">
                      <div className="name">Australia</div>
                      <div className="des">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!</div>
                      {/* <button>See More</button> */}
                  </div>
              </div>
              <div className="item" style={{backgroundImage:`url(${img5})`}}>
                  <div className="content">
                      <div className="name">Netherland</div>
                      <div className="des">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!</div>
                      {/* <button>See More</button> */}
                  </div>
              </div>
              <div className="item" style={{backgroundImage:`url(${img6})`}}>
                  <div className="content">
                      <div className="name">Ireland</div>
                      <div className="des">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab, eum!</div>
                      {/* <button>See More</button> */}
                  </div>
              </div>

          </div>
          <div className="button">
              <button className="prev"><i className="fa-solid fa-arrow-left"></i></button>
              <button className="next"><i className="fa-solid fa-arrow-right"></i></button>
          </div>
      </div>

    {/* <Footer/> */}
    </>
  );
};

export default Hardwareproject;
