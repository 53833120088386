import React, { useEffect } from 'react';
import '../css/Forth.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from '../../Images/4.jpg'
import img2 from '../../Images/5.jpg'
import img3 from '../../Images/6.jpg'
import { NavLink } from 'react-router-dom';
const Forth = () => {
  useEffect(() => {
    AOS.init({
      duration: 3000,
      once: true,
    });
  }, []);
  return (
    <>
    <div className="Forth">
    <div className="centered-h1" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
      <h1 style={{textAlign:'center'}}>Founder Directors</h1>
    </div>
    <section >
      <div className="container">
        <div className="row card-container">
          <div className="col-md-4">
            <div className="card profile-card-1">
              <img src="https://images.pexels.com/photos/946351/pexels-photo-946351.jpeg?w=500&h=650&auto=compress&cs=tinysrgb" alt="profile-sample1" className="background" />
              <img src={img1} alt="" className="profile" />
              <div className="card-content">
                <h2>Gaurav Patil<small>Director @TEGA IT SOLUTIONS</small></h2>
                <div className="icon-block">
                    <NavLink to="/#"><i className="fab fa-linkedin"></i></NavLink>
                    <NavLink to="/#"><i className="fab fa-github"></i></NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card profile-card-1">
                <img src="https://images.pexels.com/photos/946351/pexels-photo-946351.jpeg?w=500&h=650&auto=compress&cs=tinysrgb" alt="" className="background" />
                <img src={img3} alt="" className="profile" />
              <div className="card-content">
                <h2>Tejas Shrigondekar<small>Director@ TEGA IT SOLUTIONS</small></h2>
                <div className="icon-block">
                <NavLink to="https://www.linkedin.com/in/tejas-shrigondekar-184a75167/"><i className="fab fa-linkedin"></i></NavLink>
                <NavLink to="/#"><i className="fab fa-github"></i></NavLink>
                </div>
                </div>
            </div>
            </div>
           <div className="col-md-4">
            <div className="card profile-card-1">
              <img src="https://images.pexels.com/photos/946351/pexels-photo-946351.jpeg?w=500&h=650&auto=compress&cs=tinysrgb" alt="" className="background" />
              <img src={img2} alt="" className="profile" />
                <div className="card-content">
                <h2>Shailesh Kankarej<small>Director@ TEGA IT SOLUTIONS</small></h2>
                <div className="icon-block">
                <NavLink to="https://www.linkedin.com/in/shailesh-kankarej-9b6955239?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i className="fab fa-linkedin"></i></NavLink>
                <NavLink to="/#"><i className="fab fa-github"></i></NavLink>
                </div>
                </div>
            </div>
            {/* <p className="mt-3 w-100 float-left text-center"><strong>Basic Profile Card</strong></p> */}
          </div>
        </div>
      </div>
    </section>
    </div>
    </>
  )
}
export default Forth