import React, { useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import AOS from 'aos';
import 'aos/dist/aos.css';
import Cursor from '../Pages/Cursor';
const Proj = () => {
    useEffect(() => {
      AOS.init({
        duration: 1000, 
      });
    }, []);
  
    // Array of project objects
    const projects = [
      {
        number: '01',
        title: 'Bike Rental',
        imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIRzMrfdpBJUe2sPGXhtlhuosiO5SMqmRX0w&s',
        description: 'Trees are woody perennial plants that are a member of the kingdom Plantae. All species of trees are grouped by their genus, family, and order. This helps make identifying and studying trees easier. Apart from providing oxygen for the planet and beauty when they bloom or turn color, trees are very useful. Certain species of hardwood and softwood trees are excellent for timber, making furniture, and paper. When managed properly, trees are a good source of renewable energy and construction material.'
      },
      {
        number: '02',
        title: 'Hotel Management',
        imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIRzMrfdpBJUe2sPGXhtlhuosiO5SMqmRX0w&s',
        description: 'Trees are woody perennial plants that are a member of the kingdom Plantae. All species of trees are grouped by their genus, family, and order. This helps make identifying and studying trees easier. Apart from providing oxygen for the planet and beauty when they bloom or turn color, trees are very useful. Certain species of hardwood and softwood trees are excellent for timber, making furniture, and paper. When managed properly, trees are a good source of renewable energy and construction material.'
      },
      {
        number: '03',
        title: 'Bike Rental',
        imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIRzMrfdpBJUe2sPGXhtlhuosiO5SMqmRX0w&s',
        description: 'Trees are woody perennial plants that are a member of the kingdom Plantae. All species of trees are grouped by their genus, family, and order. This helps make identifying and studying trees easier. Apart from providing oxygen for the planet and beauty when they bloom or turn color, trees are very useful. Certain species of hardwood and softwood trees are excellent for timber, making furniture, and paper. When managed properly, trees are a good source of renewable energy and construction material.'
      },
      {
        number: '04',
        title: 'Bike Rental',
        imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIRzMrfdpBJUe2sPGXhtlhuosiO5SMqmRX0w&s',
        description: 'Trees are woody perennial plants that are a member of the kingdom Plantae. All species of trees are grouped by their genus, family, and order. This helps make identifying and studying trees easier. Apart from providing oxygen for the planet and beauty when they bloom or turn color, trees are very useful. Certain species of hardwood and softwood trees are excellent for timber, making furniture, and paper. When managed properly, trees are a good source of renewable energy and construction material.'
      },
      {
        number: '05',
        title: 'Bike Rental',
        imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIRzMrfdpBJUe2sPGXhtlhuosiO5SMqmRX0w&s',
        description: 'Trees are woody perennial plants that are a member of the kingdom Plantae. All species of trees are grouped by their genus, family, and order. This helps make identifying and studying trees easier. Apart from providing oxygen for the planet and beauty when they bloom or turn color, trees are very useful. Certain species of hardwood and softwood trees are excellent for timber, making furniture, and paper. When managed properly, trees are a good source of renewable energy and construction material.'
      },
      {
        number: '06',
        title: 'Bike Rental',
        imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIRzMrfdpBJUe2sPGXhtlhuosiO5SMqmRX0w&s',
        description: 'Trees are woody perennial plants that are a member of the kingdom Plantae. All species of trees are grouped by their genus, family, and order. This helps make identifying and studying trees easier. Apart from providing oxygen for the planet and beauty when they bloom or turn color, trees are very useful. Certain species of hardwood and softwood trees are excellent for timber, making furniture, and paper. When managed properly, trees are a good source of renewable energy and construction material.'
      },
      {
        number: '07',
        title: 'Bike Rental',
        imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIRzMrfdpBJUe2sPGXhtlhuosiO5SMqmRX0w&s',
        description: 'Trees are woody perennial plants that are a member of the kingdom Plantae. All species of trees are grouped by their genus, family, and order. This helps make identifying and studying trees easier. Apart from providing oxygen for the planet and beauty when they bloom or turn color, trees are very useful. Certain species of hardwood and softwood trees are excellent for timber, making furniture, and paper. When managed properly, trees are a good source of renewable energy and construction material.'
      },
    ];
  return (
    <>
    <Cursor/>
    <div className="Proj" style={{backgroundColor:'black',paddingTop:'30px',paddingBottom:'30px'}}>
    <div className="container">
    {projects.map((project, index) => (
            <div key={index} className="row">
              <div className="col-2" data-aos="zoom-in-right">
                <h1 style={{ marginTop:'100px' }}>{project.number}</h1>
              </div>
              <div className="col-6" data-aos="fade-up">
                <div className="wrap animate pop">
                  <div className="overlay">
                    <div className="overlay-content animate slide-left delay-2">
                      <h1 className="animate slide-left pop delay-4" style={{ fontSize:'6vmin', fontFamily:'cursive' }}>{project.title}</h1>
                    </div>
                    <div className="image-content"></div>
                    <div className="dots animate">
                      <div className="dot animate slide-up delay-6"></div>
                      <div className="dot animate slide-up delay-7"></div>
                      <div className="dot animate slide-up delay-8"></div>
                    </div>                
                  </div>
                  <div className="text">
                    <img className="inset" src={project.imageUrl} alt="" />
                    <p>{project.description}</p>
                  </div>
                </div>
              </div>
              <div className="col-4" data-aos="zoom-in-left">
                <a href="https://chat.openai.com/c/3f6bdcf8-b7b5-46d6-95d0-dd763ce336b7" className="arrow-right-circle" style={{ color:'white', marginTop:'100px', marginLeft:'200px' }}>
                  <i className="bi bi-arrow-right-circle" style={{ fontSize:'4.5rem' }}></i>
                </a>
              </div>
            </div>
          ))}
      
  </div>
  </div>
      </>
  )
}

export default Proj
