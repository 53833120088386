import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Secd.css';
// import Third from './Third';
// import Contact from './Contact';
// import Ours from './Ours';

import img1 from '../../Images/16.jpg';
import img2 from '../../Images/17.jpg';
import img3 from '../../Images/18.jpg';
const Secd = () => {
  return (
    <>
    <div className="sec" id="About">
  <h1 className="text-center mb-5">Technologies</h1>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-4 mb-4" >
        <img src={img1} alt="Technology" className="img-fluid" />
      </div>
      <div className="col-lg-8" >
        <h2>About Us</h2>
        <p className="text-justify">
          TEGA IT Solutions is a dynamic and experienced IT company dedicated to helping businesses thrive in the ever-evolving digital landscape.
          With over four years of experience, we offer a comprehensive range of services to empower your organization's online presence and streamline your operations.
        </p>
      </div>
    </div>
    <div className="row mt-5 align-items-center vision-container">
      <div className="col-lg-8" >
        <h2>Vision</h2>
        <p className="text-justify">
          At TEGA IT Solutions, our vision is to be the leading catalyst for digital transformation, empowering businesses and individuals to thrive in a rapidly evolving technological landscape.
          We envision a future where innovation knows no bounds, where technology serves as a bridge to new possibilities, and where every interaction is seamless, intuitive, and impactful.
          By harnessing the power of emerging technologies and embracing a culture of continuous learning and adaptation, we aim to inspire progress, drive innovation, and shape a brighter tomorrow for all.
        </p>
      </div>
      <div className="col-lg-4" >
        <img src={img2} alt="Vision" className="img-fluid" />
      </div>
    </div>
    <div className="row mt-5 align-items-center">
      <div className="col-lg-4 mb-4" >
        <img src={img3} alt="Mission" className="img-fluid" />
      </div>
      <div className="col-lg-8" >
        <h2>Mission</h2>
        <p className="text-justify">
          Our foremost priority is "Customer Satisfaction," a fundamental goal shared by every member of our team.
          Through their continuous learning, unwavering dedication, goal-oriented approach, and diligent work, we are able to meet and exceed our customers' expectations.
          Our objective is to provide loyal, high-quality services to our customers while fostering enduring relationships that ensure our lasting presence in this industry.
        </p>
      </div>
    </div>
  </div>
</div>
      {/* <Ours />
      <Third />
      <Contact /> */}
    </>
  );
};
export default Secd;