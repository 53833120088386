import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import '../Projects/Javaproj.css'
const Table = ({ data }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Sr. No</th>
          <th>Project Code</th>
          <th>Project Title</th>
          <th>Algorithm</th>
          <th>Technology</th>
          <th>Project Cost</th>
          <th>Buy Link</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td>{item.projectCode}</td>
            <td>{item.projectTitle}</td>
            <td>{item.algorithm}</td>
            <td>{item.technology}</td>
            <td>{item.projectCost}</td>
            <td>
              <a href={item.buyLink} target="_blank" rel="noopener noreferrer">
                Buy
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const projectData = [
  {
    id: 1,
    projectCode: 'P001',
    projectTitle: 'Project Alpha',
    algorithm: 'Neural Network',
    technology: 'TensorFlow',
    projectCost: '$500',
    buyLink: 'https://example.com/buy/project-alpha'
  },
  {
    id: 2,
    projectCode: 'P002',
    projectTitle: 'Project Beta',
    algorithm: 'SHA-256',
    technology: 'Ethereum',
    projectCost: '$600',
    buyLink: 'https://example.com/buy/project-beta'
  },
  {
    id: 3,
    projectCode: 'P003',
    projectTitle: 'Project Beta',
    algorithm: 'SHA-256',
    technology: 'Ethereum',
    projectCost: '$600',
    buyLink: 'https://example.com/buy/project-beta'
  },
  {
    id: 4,
    projectCode: 'P004',
    projectTitle: 'Project Beta',
    algorithm: 'SHA-256',
    technology: 'Ethereum',
    projectCost: '$600',
    buyLink: 'https://example.com/buy/project-beta'
  },
  {
    id: 5,
    projectCode: 'P005',
    projectTitle: 'Project Beta',
    algorithm: 'SHA-256',
    technology: 'Ethereum',
    projectCost: '$600',
    buyLink: 'https://example.com/buy/project-beta'
  },
  {
    id: 6,
    projectCode: 'P006',
    projectTitle: 'Project Beta',
    algorithm: 'SHA-256',
    technology: 'Ethereum',
    projectCost: '$600',
    buyLink: 'https://example.com/buy/project-beta'
  },
  {
    id: 7,
    projectCode: 'P007',
    projectTitle: 'Project Beta',
    algorithm: 'SHA-256',
    technology: 'Ethereum',
    projectCost: '$600',
    buyLink: 'https://example.com/buy/project-beta'
  },
  {
    id: 8,
    projectCode: 'P008',
    projectTitle: 'Project Beta',
    algorithm: 'SHA-256',
    technology: 'Ethereum',
    projectCost: '$600',
    buyLink: 'https://example.com/buy/project-beta'
  },

];
const Miniproj = () => {
  return (
  <>
  <Header/>
        <div className="javap" >
        <div className="head">
          <h1 >Mini Final Year Projects</h1>
        </div>
        </div>
        <div className="breadcrumbs-wrapper">
        <div className="container">
          <ul className="breadcrumbs" id="breadcrumbs">
            <li><a href="/">Home</a></li> <li>::</li> 
            <li>Mini Projects</li>
          </ul>
        </div>
      </div>
    <div className="depo">
    <h3 style={{textAlign:'center',fontSize:'40px',paddingTop:'40px',paddingBottom:'30px'}}>Mini Final Year Projects 2023 – 2024</h3>
    <p style={{marginLeft:'40px',textAlign:'justify',paddingRight:'40px'}}>Mini Projects for Computer Science students are available with Various technologies and domains. Students can choose their preferred Mini Project from the following Categories:</p>
    <h4>Mini Project Titles 2024</h4>
    <div className="data">
      <Table data={projectData} />
    </div>
    </div>
    <Footer/>
  </>
  )
}

export default Miniproj
